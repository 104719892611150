import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "source" ]

  connect() {
    console.log("Month Responsibility Controller")
  }

  change(e) {
    console.log('Changed ' + this.sourceTarget.options[1].value);
    e.target.form.requestSubmit()
  }
}