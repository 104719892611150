import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  toggle_info() {
    const info_id = this.element.id + '-contact-info'
    let ci = document.getElementById(info_id)
    ci.style.display = ci.style.display === "block" ? "none" : "block"
  }
}
